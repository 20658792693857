<template>

    <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" sm="12" md="6">

          <v-alert
              prominent
              border="left"
              :type="login_info_type"
              v-if="authenticated"
            >
              {{login_info}}
            </v-alert>

            <v-card class="mx-auto" v-if="authenticated">

              <!-- </v-img> -->
                <v-toolbar flat color="transparent">
                    <v-icon large color="accent">fas fa-key</v-icon>
                    <v-toolbar-title class="headline ml-4 primary--text">Passwort ändern</v-toolbar-title>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text>
                    <v-form ref="form" v-model="valid">

                        <v-text-field 
                            v-model="old_password" 
                            :type="showPassword_old ? 'text' : 'password'" 
                            label="Bisheriges Passwort"
                            prepend-icon="fas fa-key"
                            :append-icon="showPassword_old ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            @click:append="showPassword_old = !showPassword_old"
                        />

                        <v-text-field 
                            v-model="new_password" 
                            :type="showPassword_new ? 'text' : 'password'" 
                            :rules="[passwordRules.rule1, passwordRules.rule2, passwordRules.rule3, passwordRules.rule4, passwordRules.rule5, passwordRules.rule6, passwordRules.rule7, (new_password !== old_password) || 'Das neue Passwort darf nicht mit dem alten Passwort identisch sein.']"
                            label="Neues Passwort"
                            prepend-icon="fas fa-key"
                            :append-icon="showPassword_new ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            @click:append="showPassword_new = !showPassword_new"
                        />

                        <v-text-field 
                            v-model="new_password_validated" 
                            :type="showPassword_validated ? 'text' : 'password'" 
                            :rules="[(new_password === new_password_validated) || 'Die beiden neuen Passwörter müssen identisch sein.']"
                            label="Neues Passwort wiederholen"
                            prepend-icon="fas fa-key"
                            :append-icon="showPassword_validated ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            @click:append="showPassword_validated = !showPassword_validated"
                        />

                    </v-form>
                    
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="my-4">

                    <v-spacer></v-spacer>

                    <v-btn class="mr-4 mb-4" color="primary" rounded outlined @click="change_password">Passwort ändern</v-btn>

                </v-card-actions>
            </v-card>

        </v-col>
    </v-row>
</template>

<script>
import authentication from '@/_auth/auth'

export default {

  name: 'change-initial-password',

  data () {
    return {
      showPassword_new: false,
      showPassword_old: false,
      showPassword_validated: false,
      new_password_validated: '',
      new_password: '',
      old_password: '',

      value: true,

      passwordRules: {

        rule1: v => !!v || 'Sie müssen ein neues Passwort eingeben.',
        rule2: v => (v && v.length >= 12) || 'Das Passwort muss mindestens 12 oder mehr Zeichen enthalten.',
        rule3: v => /(?=.*[A-Z])/.test(v) || 'Das Passwort muss mindestens einen Großbuchstaben enthalten.',
        rule4: v => /(?=.*\d)/.test(v) || 'Das Passwort muss mindestens eine Zahl enthalten.',
        rule5: v => /(?=.*[a-z])/.test(v) || 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
        rule6: v => /(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(v) || 'Das Passwort muss mindestens eines der folgenden Sonderzeichen enthalten: !, @, #, $, %, ^, &, *, (, ), _, +, -, =, [, ], {, }, ;, ", |, ., <, >, /, ?',
        rule7: v => /^[a-zA-Z\d]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]?[a-zA-Z\d]*$/.test(v) || 'Das Passwort darf höchstens ein Sonderzeichen enthalten.'

      },

      valid: true,
      
    }
  },

  computed: {

    authenticated() {
      return this.$store.getters['store_auth/get_is_authenticated']
    },

    loading() {
      return this.$store.getters['store_loader/get_show_main_loader']
    },

    login_info() {
      return 'Aus Sicherheitsgründen müssen Sie zunächst Ihr Passwort ändern. Geben Sie dazu bitte zunächst Ihr bisheriges Passwort ein. Geben Sie dann ein neues Passwort ein und wiederholen Sie dieses.'
    },

    login_info_type() {
      return this.$store.getters['store_auth/get_login_info_type']
    }

  },

  methods: {

    change_password () {

      if (this.$refs.form.validate()) {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        var data = {
            old_password: this.old_password,
            new_password: this.new_password,
            new_password_validated: this.new_password_validated
        }

          authentication.ChangePassword(data).then(() => {

            this.$store.dispatch('store_auth/UPDATE_PASSWORD_CHANGED', true)

            if (this.$store.getters['store_auth/get_user_group'] == 'Client') {

              this.$router.push('/client')
        
            } else if (this.$store.getters['store_auth/get_user_group'] == 'Supervisor') {
  
              this.$router.push('/supervisor')
        
            } else if (this.$store.getters['store_auth/get_user_group'] == 'Coach') {
  
              this.$router.push('/coach')
        
            }

            this.$store.dispatch('store_dialogs/UPDATE_GLOBAL_SNACKBAR_TEXT', 'Passwort wurde erfolgreich geändert!')
            this.$store.dispatch('store_dialogs/UPDATE_SHOW_GLOBAL_SNACKBAR', true)

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })
        
      }

    },

  }

}
</script>
